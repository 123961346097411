export const csvHeadersData = [
  { label: "Account Name", key: "accountName" },
  { label: "Worksite Name", key: "siteName" },
  { label: "Asset Name", key: "assetName" },
  { label: "User Name", key: "userName" },
  { label: "User Type", key: "userType" },
  { label: "Date/Time", key: "dateTimeFormatted" },
  { label: "End Date", key: "endDateTimeFormatted" },
  { label: "Event Category", key: "eventCategory" },
  { label: "Event", key: "systemEvent" },

  { label: "Unlock Method", key: "unlockMethod" },
  { label: "Connection Type", key: "connectionType" },
  { label: "Login Method", key: "loginMethod" },
  { label: "Config Update Type", key: "configUpdateType" },
];

export const categoryValueToLabelMap = {
  admin_menu_access: "Admin Menu Access",
  config_update: "Config Update",
  device_shutdown: "Device Shutdown",
  fe_auth: "FE Auth",
  internet_activity: "Internet Activity",
  lock_unlock: "Lock/Unlock",
};

export const eventValueToLabelMapping = {
  admin_menu_login: "Admin Menu Login",
  config_update: "Config Update",
  fe_login: "FE Login",
  i_connected: "Connected",
  i_disconnected: "Disconnected",
  lock: "Lock",
  unlock: "Unlock",
  device_shutdown: "Device Shutdown",
  restart: "Restart",
  shutdown: "Shutdown",
};
