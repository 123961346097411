import React from "react";
import { Navbar } from "react-bootstrap";
import { CopyrightText, FooterWrapper } from "./Footer.styles";
import { Trans } from "react-i18next";

const Footer = () => {
  const version = import.meta.env?.VITE_APP_VERSION;
  const year = new Date().getFullYear();

  return (
    <Navbar className="footerNav" collapseOnSelect expand="lg">
      <FooterWrapper>
        <div style={{ width: "100px" }}></div>
        <CopyrightText>
          <Trans i18nKey="FOOTER_COPYRIGHT_TEXT">
            Proudly built in Austin, TX. Copyright {{ year }} Stocked Robotics, Inc. dba SIERA.AI
          </Trans>
        </CopyrightText>
        <div style={{ width: "100px", textAlign: "right" }}>v. {version}</div>
      </FooterWrapper>
    </Navbar>
  );
};

export default Footer;
