import styled from "styled-components";

export const SearchTextHeighlight = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  display: initial;
`;

export const ModalLibrary = styled.div``;

export const ModalLibrarySection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ModalLeftLibraryAll = styled.div`
  flex: 1;
`;

export const MainLibraryDiv = styled.div`
  display: block;
  position: relative;
  height: 100%;
`;
export const ContMainDiv = styled.div`
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;
export const LibraryUl = styled.ul`
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;

  overflow-x: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  min-height: 10px;
  li {
    padding: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font6} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-weight: 500;
    padding-left: 20px;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    span {
      font-family: ${({ theme }) => theme.fontFamily.primaryFont};
      font-size: ${({ theme }) => theme.fontSizes.font7} !important;
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      width: 92%;
    }
    i {
      font-size: ${({ theme }) => theme.fontSizes.font6} !important;
      color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
      line-height: ${({ theme }) => theme.lineHeights.line7};
      position: relative;
      right: 5px;
    }
    i.lefti {
      margin-right: 10px;
      cursor: pointer;
      position: relative;
      top: 2px;
    }
    i.righti {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
      margin-right: 8px;
      :hover {
        color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      }
    }
    i.back-add {
      /*background-color: rgb(13, 95, 190);
      color: rgb(255, 255, 255);*/
      font-size: ${({ theme }) => theme.fontSizes.font4} !important;
      position: relative;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      right: 16px;
      :hover {
      }
    }
  }
  .wrong-drop {
    background-color: red;
  }
  li.NoMoreMessage {
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
`;

export const ChartLibraryName = styled.div`
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  margin: 0px 0px 16px 0px;
  padding: 8px 0px;
  overflow: hidden;
  span {
    margin: 8px;
    line-height: ${({ theme }) => theme.lineHeights.line8};
    float: left;
    font-size: ${({ theme }) => theme.fontSizes.font4};
  }
  > div:first-child {
    margin-top: 0px;
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    > div:first-child {
      color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      font-size: ${({ theme }) => theme.fontSizes.font4} !important;
    }
    > div:nth-of-type(3) {
      background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      > div:first-child {
        filter: invert(1);
      }
    }
    input:placeholder {
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
      font-weight: 400;
    }
    input:focus {
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      font-weight: 500;
      > div:second-child {
      }
    }
  }
`;

export const ChartLibraryStyledDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};

  height: calc(100vh - 280px);
  @media screen and (max-width: 800px) {
    height: calc(100vh - 480px);
  }

  overflow: auto;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
`;

export const LibraryHeading = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  display: flex;
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  span {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font6} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-weight: 600;
    width: 88%;
  }
  i {
    font-size: ${({ theme }) => theme.fontSizes.font6} !important;
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    line-height: ${({ theme }) => theme.lineHeights.line7};
    margin-right: 5px;
  }
  i.lefti {
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    top: 2px;
  }
  i.righti {
    cursor: pointer;
  }
`;

export const ButtonAddWrapp = styled.div`
  display: block;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  button {
    flex: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    max-width: fit-content;
  }
`;

export const IconUpSide = styled.div`
  float: right;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  height: 24px;
  width: 20px;
  position: relative;
  right: -16px;
`;

export const IconDownSide = styled.div`
  float: right;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  height: 20px;
  width: 20px;
  position: relative;
  right: -16px;
`;
export const ErroMessageStyle = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  width: 98%;
  margin: 8px auto;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  margin-bottom: 10px;
`;

export const NoteMessage = styled.div`
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  width: 98%;
  margin: 8px auto;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;

export const ChartButtonDiv = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: 98%;
  height: -webkit-fill-available;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
